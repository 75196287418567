<template>
    <div class="page padd30">
        <to-history :prop-message="title"></to-history> 
        <div class="search_wap">
            <div class="a-inline-block">
                <a-input-search v-model="osn" class="sInput" placeholder="请输入订单编号" @search="onSearch"/>
            </div>
        </div>
        <div v-infinite-scroll="getRecord" infinite-scroll-disabled="dataLoadStop" infinite-scroll-distance="50">
            <div class="lists" v-for="(item,index) in data" :key="index">
                <div class="list_row">
                    <div class="list_lable" style="padding-bottom: 2rem;margin-bottom: 2rem;border-bottom: 1px solid rgba(0, 0, 0, .1);font-weight: bold;">订单编号：{{item.osn}}</div>
                    <div class="list_lable">客户名称：<span>{{ item.name }}</span></div>
                    <div class="list_lable">跟进人：<span>{{ item.staff_uid }}</span></div>
                    <div class="list_lable">金额：<span>{{ item.amount }}</span></div>
                    <div class="list_lable">支付方式：<span>{{ item.method }}</span></div>
                    <div class="list_lable">收款批次：<span>{{ item.batch }}</span></div>
                    <div class="list_lable">回款时间：<span>{{ item.receipt_time }}</span></div>
                    <div class="list_lable">回款备注：<span>{{ item.doc }}</span></div>
                </div>
                
                <div class="flex btn_con1">
                    <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(item.id)">
                        <a-button ghost>删除</a-button>
                    </a-popconfirm>
                </div>
            </div>
            <div class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>{{dataLoadNomore ? "--没有更多数据了--" : "加载中..."}}</span>
            </div>
        </div>
        
    </div>
</template>
<script>
// import $ from "jquery";
import infiniteScroll from "vue-infinite-scroll";
import '../../../assets/css/wapList.css';
import {requestXml,getDateTime} from '../../../assets/js/request';
export default {
    directives: {infiniteScroll},
    data() {
        return {
            // 搜索的内容
            osn: "",
            data:[],
            methodArr: ["公户打款","微信","支付宝","刷卡","现金"],

            // 分页
            dataLoadStop: false,  //列表数据加载停止状态
            dataLoadNomore: false,    //列表数据加载是否完成
            pagination: {
                current: 1,
                total: 100,
                pageSize: 10, // 默认每页显示数量
                showSizeChanger: true, // 显示可改变每页数量
                pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
                // showTotal: total => `Total ${total} items`, // 显示总数
                onChange: (current) => this.pagesChange(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {
                    //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize);
                },
            },
            title:this.$route.query.title
        };
    },
    mounted: function(){
        this.getRecord();
    },
    methods:{
        // 获取回款列表
        getRecord() {
            this.dataLoadStop = true;
            requestXml("/scrm/Order/getPaymentList","POST",(res) => {
                for(let i=0;i<res.list.length;i++){
                    res.list[i].receipt_time = getDateTime(res.list[i].receipt_time);
                    res.list[i].created = getDateTime(res.list[i].created);
                    res.list[i].method = this.methodArr[res.list[i].method - 1];
                }
                this.data = this.data.concat(res.list);
                if(res.page.maxpage>this.pagination.current){
                    this.pagination.current += 1
                    this.dataLoadStop = false
                }else{
                    this.dataLoadNomore = true
                }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
            },{ "type":1,"osn": this.osn,"page":{"curpage":this.pagination.current,"pagesize":this.pagination.pageSize}})
        },
        onSearch(){
            this.data=[];
            this.dataLoadNomore=false;
            this.pagination.current=1;
            this.getRecord();
        },
        // 删除
        onDelete(id){
            requestXml("/scrm/Order/delPayment","POST",(res) => {
                console.log(res);
                if(res == "success"){
                    this.data = [];
                    this.getRecord();
                }
            },{"id": id})
        }
        
    }
};
</script>
<style scoped>
.page {
    background-image: url('../../../assets/imgs/wap/bg.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    padding-bottom: 50px;
}

/deep/.ant-input {
    background: #3056BF !important;
    color: #fff;
}
/deep/ .ant-input::placeholder,
/deep/ .ant-select::placeholder {
    color: #fff;
    font-size: 12px;
    /* 将占位符文本颜色设置为灰色 */
}

/deep/ .anticon svg,
/deep/ .ant-select svg {
    color: #fff;
}

.lists {
    padding: 0;
    border-radius: 20px;
}

.list_row {
    padding: 3rem;
}

.btn_con {
    border-top: 1px solid rgba(0, 0, 0, .1);
    display: flex;
    margin: 3rem;
    padding-top: 3rem;
}

.btn_con button {
    width: 200px;
    height: 40px;
    line-height: 18px;
    color: #fff;
    border-radius: 30px;
    border: none;
    font-size: 18px;
}

.btn_con button img {
    height: 18px;
    vertical-align: middle;
    margin-right: 5px;
}

.btn_con1 {
    background: #FFF5E9;
    border-radius: 20px;
    padding: 10px 0;
    box-sizing: border-box;
}

.btn_con1 button {
    width: 100%;
    height: 30px;
    line-height: 18px;
    color: #FF9100;
    border-radius: 0;
    border: none;
    font-size: 18px;
}
.list_lable{
    color: #666666;
}
.list_lable span{
    color: #000000;
    float: right;
}
.barStyB{
    background: #00A200;
    border-radius: 20px 0px 20px 0px;
    color: #FFFFFF !important;
    font-weight: 200;
    display: inline-block;
    padding: 0 10px;
}
.barStyR{
    background: #436DBB;
    border-radius: 20px 0px 20px 0px;
    color: #FFFFFF !important;
    font-weight: 200;
    padding: 0 10px;
}
</style>

